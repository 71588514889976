@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;

    --ring: 215 20.2% 65.1%;

    --radius: 0.5rem;
  }
  .selected {
    @apply bg-indigo-200 !important;
  }  
}

@media (prefers-color-scheme: dark) {
  :root {
    --background: 224 71% 4% !important;
    --foreground: 213 31% 91%;

    --muted: 223 47% 11%;
    --muted-foreground: 215.4 16.3% 56.9%;

    --accent: 216 34% 17%;
    --accent-foreground: 210 40% 98%;

    --popover: 224 71% 4%;
    --popover-foreground: 215 20.2% 65.1%;

    --border: 216 34% 17%;
    --input: 216 34% 17%;

    --card: 224 71% 4%;
    --card-foreground: 213 31% 91%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 1.2%;

    --secondary: 222.2 47.4% 11.2%;
    --secondary-foreground: 210 40% 98%;

    --destructive: 0 63% 31%;
    --destructive-foreground: 210 40% 98%;

    --ring: 216 34% 17%;

    --radius: 0.5rem;
  }

  .selected {
    @apply bg-zinc-700 !important;
  }

  .ce-block--selected .ce-block__content,
  .ce-inline-toolbar,
  .codex-editor--narrow .ce-toolbox,
  .ce-conversion-toolbar,
  .ce-settings,
  .cdx-button,
  .ce-popover,
  .ce-toolbar__plus:hover,
  .ce-popover-item:hover {
    background: #27272A !important;
    color: inherit;
  }

  .ce-settings__button,
  .ce-toolbar__settings-btn {
    color: inherit;
  }

  .ce-inline-tool,
  .ce-conversion-toolbar__label,
  .ce-toolbox__button,
  .cdx-settings-button,
  .ce-popover-item,
  .ce-toolbar__plus {
    color: inherit;
  }

  ::selection {
    background: #52525B;
  }

  .cdx-settings-button:hover,
  .ce-settings__button:hover,
  .ce-toolbox__button--active,
  .ce-toolbox__button:hover,
  .cdx-button:hover,
  .ce-inline-toolbar__dropdown:hover,
  .ce-inline-tool:hover,
  .ce-popover__item:hover,
  .ce-toolbar__settings-btn:hover {
    background-color: #27272A;
    color: inherit;
  }

  .cdx-notify--error {
    background: #fb5d5d !important;
  }

  .cdx-notify__cross::after,
  .cdx-notify__cross::before {
    background: white;
  }

  .ce-popover__container {
    background-color: #111111;
    border: none;
  }

  .cdx-search-field {
    background-color: #111111;
  }

  .ce-popover-item-separator__line {
    @apply bg-zinc-600;
  }

  .tc-wrap {
    --color-border: #27272A;
  }

  .tc-toolbox {
    --toggler-dots-color: rgb(225, 231, 239);
    --toggler-dots-color-hovered: rgb(225, 231, 239);
  }

  .tc-wrap {
    --color-background: #18181B;
  }

  .tc-popover {
    --color-background: #18181B;
    border: #18181B;
    --color-background-hover: #27272A;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
    font-feature-settings: "rlig" 1, "calt" 1;
  }
}

h1 {
  @apply text-2xl font-bold;
}

h2 {
  @apply text-xl font-bold;
}

h3 {
  @apply text-lg font-bold;
}

h4 {
  @apply text-base font-bold;
}

h5 {
  @apply text-sm font-bold;
}

h6 {
  @apply text-xs font-bold;
}